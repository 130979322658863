import React, { useState, useEffect } from "react";
import { Typography, Grid } from '@mui/material';

import httpProvider from "../provider/HttpProvider";
import CustomAlert from '../components/CustomAlert';

const Stats = () => {
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
    const [bookmarkStats, setBookmarkStats] = useState({
        "empty_tags": 0,
        "total": 0,
    });
    const [loadingBookmarkStats, setLoadingBookmarkStats] = useState(true);
    const [tagsStats, setTagsStats] = useState({
        "total": 0,
    });
    const [loadingTagsStats, setLoadingTagsStats] = useState(true);

    useEffect(() => {
        httpProvider
            .get('/api/bookmarks/stats')
            .then((response) => {
                setBookmarkStats(response);
                setLoadingBookmarkStats(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
            });

        httpProvider
            .get('/api/tags/stats')
            .then((response) => {
                setTagsStats(response);
                setLoadingTagsStats(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
            });
    }, []);

    return (
        <Grid container direction="column" alignItems="left">
            <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
            {!loadingBookmarkStats && !loadingTagsStats && (
                <Grid container item xs={12}>
                    <Grid item xs={2}>
                        <Typography variant="button">
                            Bookmarks:{bookmarkStats.total}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="button">
                            Bookmarks without tags: {bookmarkStats.empty_tags}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="button">
                            Total Tags: {tagsStats.total}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default Stats;
