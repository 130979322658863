import React, { useEffect, useState } from 'react';
import httpProvider from '../provider/HttpProvider';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography, IconButton, Tooltip, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomAlert from '../components/CustomAlert';

const PocketBookmarks = ({ onAddSuccess, onAddError }) => {
    const [bookmarks, setBookmarks] = useState([]);
    const [loading, setLoading] = useState(true); // Loader for fetching bookmarks
    const [submitLoading, setSubmitLoading] = useState(false); // Loader for submitting URL
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });

    useEffect(() => {
        fetchBookmarks();
    }, []);

    const fetchBookmarks = async () => {
        setLoading(true); // Show loader when fetching bookmarks
        try {
            const response = await httpProvider.get('/api/bookmarks/pocket');
            setBookmarks(response);
        } catch {
            setAlertInfo({ message: 'Failed to fetch bookmarks', severity: 'error' });
        } finally {
            setLoading(false); // Hide loader when fetching completes
        }
    };

    const handleUrlSubmit = async (url) => {
        if (!/^https?:\/\/.+/.test(url)) {
            setAlertInfo({ message: 'URL is invalid', severity: 'error' });
            onAddError?.('Invalid URL');
            return;
        }
        setSubmitLoading(true); // Show loader on submit
        try {
            await httpProvider.get(`/api/bookmarks/add?source=web&url=${btoa(url)}`);
            setAlertInfo({ message: 'Bookmark added successfully!', severity: 'success' });
            fetchBookmarks();
            onAddSuccess?.('Bookmark added successfully!');
        } catch (error) {
            setAlertInfo({ message: 'Failed to add bookmark', severity: 'error' });
            onAddError?.('Failed to add bookmark');
        } finally {
            setSubmitLoading(false); // Hide loader after submit completes
        }
    };

    if (loading) return <CircularProgress />; // Show loader while fetching bookmarks

    return (
        <Box mt={2}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Pocket Bookmarks Importer</Typography>
                <Tooltip title="Refresh File List">
                    <IconButton color="primary" onClick={fetchBookmarks}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

            {/* Display loader while submitting a URL */}
            {submitLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookmarks.data?.map(({ item_id, resolved_title, given_title, resolved_url }) => (
                                <TableRow key={item_id}>
                                    <TableCell>{resolved_title || given_title}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleUrlSubmit(resolved_url)}
                                            disabled={submitLoading} // Disable button while submitting
                                        >
                                            {submitLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit URL'}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default PocketBookmarks;
