import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import httpProvider from '../provider/HttpProvider';

const DragDropUpload = () => {
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(null);

    const onDrop = (acceptedFiles) => {
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
            formData.append('files', file);
        });

        setLoading(true);
        setSuccess(false);
        setError(null);

        httpProvider.post('/api/upload/local', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                console.log(response.data);
                setLoading(false);
                setSuccess(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
                setError('Upload failed. Please try again.');
            });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: '2px dashed grey',
                borderRadius: '8px',
                padding: '100px',
                textAlign: 'center',
                bgcolor: isDragActive ? 'lightblue' : 'background.paper',
                transition: 'background-color 0.3s',
            }}
        >
            <input {...getInputProps()} />
            {loading ? (
                <CircularProgress />
            ) : (
                <Typography variant="h6">
                    {isDragActive ? "Drop the files here ..." : "Drag 'n' drop some files here, or click to select files"}
                </Typography>
            )}
            {success && <Alert severity="success">Files uploaded successfully!</Alert>}
            {error && <Alert severity="error">{error}</Alert>}
        </Box>
    );
};

export default DragDropUpload;
