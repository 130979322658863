import React from 'react';
import { AreaChart, Brush, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer, CartesianGrid } from 'recharts';
import CustomTooltip from './CustomTooltip';

const WeatherHeroGraph = ({ data }) => {
    return (
        <ResponsiveContainer aspect={1.0 / .5}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <YAxis />
                <Legend />
                <Brush />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="0" stroke="#118ab2" label="0°C" />
                <Area name="Temperature" dataKey="temperature" unit="°C" stroke="#82ca9d" fill="#82ca9d" />
                <Area name="Light" dataKey="light" unit="%" stroke="#ffc658" fill="#ffc658" activeDot={{ r: 8 }} />

            </AreaChart>
        </ResponsiveContainer>
    );
}

export default WeatherHeroGraph;
