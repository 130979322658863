import React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SensorTable = ({ mainChart, setMainChart, loadingWeather }) => {

    // Function to handle delete action
    const handleDelete = async (id) => {
        console.log('Deleting row:', id);
        if (!id) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SENSORS_API}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `${process.env.REACT_APP_SENSORS_API_TOKEN}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ids: [id] })
            });

            if (!response.ok) {
                throw new Error('Failed to delete sensor data');
            }

            // Update state to remove deleted row
            setMainChart(prevData => prevData.filter(row => row.id !== id));
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    return (
        !loadingWeather && (
            <Grid item xs={12} sx={{ marginTop: 10 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table stickyHeader size="small" aria-label="sensor readings">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell align="right">Light (%)</StyledTableCell>
                                <StyledTableCell align="right">Pressure (hPa)</StyledTableCell>
                                <StyledTableCell align="right">Altitude (m)</StyledTableCell>
                                <StyledTableCell align="right">Wind Speed (m/s)</StyledTableCell>
                                <StyledTableCell align="right">Humidity (%)</StyledTableCell>
                                <StyledTableCell align="right">Temperature (°C)</StyledTableCell>
                                <StyledTableCell align="right">Air Quality (PPM)</StyledTableCell>
                                <StyledTableCell align="right">Device Local IP</StyledTableCell>
                                <StyledTableCell align="right">Device Name</StyledTableCell>
                                <StyledTableCell align="right">Device RSSI</StyledTableCell>
                                <StyledTableCell align="right">Device Version</StyledTableCell>
                                <StyledTableCell align="right">Actions</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {mainChart?.map((row, index) => (
                                <StyledTableRow key={row.date || index}>
                                    <StyledTableCell>{row.date ?? 'N/A'}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.light) ? 'N/A' : parseInt(row.light)}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.pressure) ? 'N/A' : row.pressure}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.altitude) ? 'N/A' : row.altitude}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.wind_speed) ? 'N/A' : parseInt(row.wind_speed)}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.humidity) ? 'N/A' : row.humidity}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.temperature) ? 'N/A' : row.temperature}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.gas_analog) ? 'N/A' : row.gas_analog}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_local_ip ?? 'N/A'}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_name ?? 'N/A'}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.device_rssi) ? 'N/A' : row.device_rssi}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_version ?? 'N/A'}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Delete
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    );
};

export default SensorTable;
