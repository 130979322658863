import React, { useRef, useEffect, useState } from 'react';
import ThreeDotsLoader from './ThreeDotsLoader';
import Clock from './Clock';

const Video = () => {
    const videoRef = useRef(null);
    const [videoState, setVideoState] = useState({
        currentVideo: '',
        nextVideo: '',
        loading: true,
        transitioning: false,
    });

    const videos = [
        "/video/black-hole.mp4",
        "/video/island.mp4",
        "/video/mountains.mp4",
        "/video/sun.mp4",
        "/video/treetop.mp4",
        "/video/glacier.mp4",
        "/video/glacier-volcano.mp4"
    ];

    // Function to select a video based on the current time
    const selectVideoBasedOnTime = () => {
        const timeSeed = Math.floor(Date.now() / 1000); // Use seconds since epoch
        const index = timeSeed % videos.length; // Determine video index based on time seed
        return videos[index];
    };

    const fetchAndCacheVideo = async (videoUrl) => {
        try {
            const cacheName = 'video-cache';
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(videoUrl);

            if (cachedResponse) {
                const blob = await cachedResponse.blob();
                return URL.createObjectURL(blob);
            } else {
                const response = await fetch(videoUrl);
                const responseClone = response.clone();
                await cache.put(videoUrl, responseClone);
                const blob = await response.blob();
                return URL.createObjectURL(blob);
            }
        } catch (error) {
            console.error('Video fetch failed', error);
            return null;
        }
    };

    useEffect(() => {
        const loadInitialVideo = async () => {
            const initialVideo = selectVideoBasedOnTime();
            const videoUrl = await fetchAndCacheVideo(initialVideo);
            setVideoState({
                currentVideo: videoUrl,
                nextVideo: videoUrl,
                loading: false,
                transitioning: false
            });
        };

        loadInitialVideo();
    }, []);

    const handleVideoLoaded = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleVideoEnded = async () => {
        setVideoState(prevState => ({
            ...prevState,
            transitioning: true,
            loading: true
        }));

        // Preload the next video
        const nextVideo = selectVideoBasedOnTime();
        const nextVideoUrl = await fetchAndCacheVideo(nextVideo);
        setVideoState(prevState => ({
            ...prevState,
            nextVideo: nextVideoUrl
        }));

        // Start the transition to the next video
        setTimeout(() => {
            setVideoState(prevState => ({
                ...prevState,
                currentVideo: prevState.nextVideo,
                transitioning: false,
                loading: false
            }));
        }, 500); // Adjust duration to match transition time
    };

    return (
        <>
            {videoState.loading && <ThreeDotsLoader />}
            <div style={styles.videoWrapper}>
                <video
                    ref={videoRef}
                    src={videoState.currentVideo}
                    type="video/mp4"
                    autoPlay
                    muted
                    playsInline
                    preload="auto"
                    onLoadedData={handleVideoLoaded}
                    onEnded={handleVideoEnded}
                    style={{
                        ...styles.video,
                        opacity: videoState.transitioning ? 0 : 1,
                        transition: 'opacity 0.5s ease-out',
                    }}
                />
                {videoState.nextVideo && (
                    <video
                        src={videoState.nextVideo}
                        type="video/mp4"
                        autoPlay
                        muted
                        playsInline
                        preload="auto"
                        style={{
                            ...styles.video,
                            opacity: videoState.transitioning ? 1 : 0,
                            transition: 'opacity 0.5s ease-in',
                        }}
                    />
                )}
                <Clock />
            </div>
        </>
    );
};

const styles = {
    videoWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1,
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'opacity 0.5s ease',
    },
    loader: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        fontSize: '24px',
        color: '#fff',
    }
};

export default Video;
