import { Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from "react";

function NotificationButton({ getCurrentSensorValues }) {
    const [subscribed, setSubscribed] = useState(false);
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const [pushTimeout, setPushTimeout] = useState(60000); // Default: 1 min
    let notificationInterval = null;

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            return;
        }

        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                setNotificationEnabled(true);
            } else {
                console.log("Notification permission denied.");
            }
        });

        return () => clearInterval(notificationInterval); // Cleanup on unmount
    }, []);

    const sendNotification = async () => {
        if (!notificationEnabled) return;

        try {
            const sensor = await getCurrentSensorValues();
            if (!sensor) {
                console.log("No sensor data available.");
                return;
            }

            const message = `
Temperature: ${sensor.temperature}°C, Humidity: ${sensor.humidity}%
Light: ${sensor.light} lux, Pressure: ${sensor.pressure} hPa
Altitude: ${sensor.altitude} m, Wind Speed: ${sensor.wind_speed} m/s
Gas Analog: ${sensor.gas_analog}, Gas Digital: ${sensor.gas_digital}
Device IP: ${sensor.device_local_ip}, Device RSSI: ${sensor.device_rssi} dBm
            `;

            console.log("Notification sent:", message);

            new Notification(`${sensor.device_name}`, { body: message });

        } catch (error) {
            console.error("Error fetching sensor data:", error);
        }
    };

    const toggleSubscription = () => {
        if (subscribed) {
            clearInterval(notificationInterval);
            setSubscribed(false);
            console.log("Notifications disabled.");
        } else {
            setSubscribed(true);
            notificationInterval = setInterval(sendNotification, pushTimeout);
            console.log("Notifications enabled.");
            sendNotification(); // Send first notification immediately
        }
    };

    const handleTimeoutChange = (event) => {
        const newTimeout = event.target.value;
        setPushTimeout(newTimeout);

        if (subscribed) {
            clearInterval(notificationInterval);
            notificationInterval = setInterval(sendNotification, newTimeout);
            sendNotification(); // Immediately send a notification on change
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" onClick={toggleSubscription}>
                    {subscribed ? "Disable Notifications" : "Enable Notifications"}
                </Button>
            </Grid>
            <Grid item>
                <FormControl>
                    <InputLabel id="pushTimeout-label">Push Timeout</InputLabel>
                    <Select
                        labelId="pushTimeout-label"
                        value={pushTimeout}
                        onChange={handleTimeoutChange}
                        label="Push Timeout"
                    >
                        <MenuItem value={60000}>1 minute</MenuItem>
                        <MenuItem value={300000}>5 minutes</MenuItem>
                        <MenuItem value={600000}>10 minutes</MenuItem>
                        <MenuItem value={600000 * 20}>20 minutes</MenuItem>
                        <MenuItem value={600000 * 40}>30 minutes</MenuItem>
                        <MenuItem value={600000 * 60}>1 hour</MenuItem>
                        <MenuItem value={600000 * 120}>2 hours</MenuItem>
                        <MenuItem value={600000 * 180}>3 hours</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={sendNotification}>
                    Get Notification Now
                </Button>
            </Grid>
        </Grid>
    );
}

export default NotificationButton;
