import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import CustomAlert from '../components/CustomAlert';
import Copyright from '../components/Copyright';
import ThreeDotsLoader from "../components/ThreeDotsLoader";

const Login = () => {
    const { token, setToken } = useAuth(); // Access the token and setToken from AuthProvider
    const navigate = useNavigate();
    const landingURL = '/weather/';

    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });

    useEffect(() => {
        if (token) {
            navigate(landingURL); // Redirect if already logged in
        }
    }, [token, navigate]);

    const handleNavigation = () => {
        if (document.referrer.startsWith(window.location.origin) && document.referrer !== `${window.location.origin}/`) {
            navigate(-1);
        } else {
            navigate(landingURL);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        setCredentials({
            email: data.get('email'),
            password: data.get('password'),
        });
        try {
            if (!credentials.email || !credentials.password) {
                setAlertInfo({ message: 'Email and Password are required' });
                return;
            }

            const host = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_HOST_PORT}`;
            const response = await fetch(`${host}/api/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });

            if (response.ok) {
                const data = await response.json();
                setToken(data.token);
                handleNavigation();
            } else {
                setAlertInfo({ message: 'Login failed' });
            }
        } catch (error) {
            setAlertInfo({ message: error.message });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {!loading && <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />}
            {loading && <ThreeDotsLoader />}
            {!loading &&
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={credentials.email}
                            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={credentials.password}
                            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
            }
            <Copyright sx={{ mt: 12, mb: 4 }} />
        </Container>
    );
};

export default Login;
