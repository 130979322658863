import React, { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';

import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import DragDropUpload from "../components/DragDropUpload";
import FileList from "../components/FileList";

const Downloads = () => {

  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Downloads' }
  ];

  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const token = localStorage.getItem("token")

  useEffect(() => {
  }, [token]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }} >
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid container direction="row" justifyContent="flex-end" spacing={0} alignItems="top">

        <Grid item xs={12}>
          <DragDropUpload />
          <FileList />
        </Grid>

      </Grid>
    </Grid >
  )
}

export default Downloads
