import React from 'react';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {payload.length == 2 ?
                    <span>
                        <small>{`${payload[0].payload.date}`}</small>
                        <p className="label">{`${parseFloat(payload[1].value).toFixed(2)} ${payload[1].unit} | ${payload[1].name}`}</p>
                        <p className="label">{`${parseFloat(payload[0].value).toFixed(2)} ${payload[0].unit} | ${payload[0].name}`}</p>
                    </span>
                    : <p className="label">{`${parseFloat(payload[0].value).toFixed(2)} ${payload[0].unit} | ${label}`}</p>}
            </div>
        );
    }

    return null;
};

export default CustomTooltip;