import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Tab, IconButton } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography } from '@mui/material';

import dayjs from 'dayjs';
import httpProvider from '../provider/HttpProvider';
import TasksTable from '../components/TasksTable';
import Breadcrumb from '../components/Breadcrumb';
import TaskForm from '../components/forms/TasksForm';
import CustomAlert from '../components/CustomAlert';
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import TagsComponent from "../components/TagsComponent";

const Tasks = () => {
  const navigate = useNavigate();
  const token = useMemo(() => localStorage.getItem("token"), []);
  const [pageItems, setPageItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabContextValue, setTabContextValue] = useState('1');
  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const [isTagSelected, setIsTagSelected] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [currentDate, setCurrentDate] = useState(dayjs().format('YYYY-MM-DD'));

  const breadcrumbs = useMemo(() => [
    { label: 'Home', href: '/' },
    { label: 'Tasks' }
  ], []);

  const tabs = useMemo(() => [
    { label: 'Todo', value: '1' },
    { label: 'Done', value: '2' },
    { label: 'Today', value: '3' },
    { label: 'This Month', value: '4' },
  ], []);

  const fetchTasks = useCallback(() => {
    setLoading(true);
    const isCompleted = tabContextValue === '2';
    let url = `/api/tasks/?is_completed=${isCompleted}&sort=due_date&sortDesc=false&limit=1000`;

    if (tabContextValue === '3') { // Fetch tasks for "Today" tab
      url = `/api/tasks/?due_date=${currentDate}&limit=1000`;
    }

    if (tabContextValue === '4') { // Fetch tasks for "This Month" tab
      const thisMonth = dayjs().format('YYYYMM')
      url = `/api/tasks/?due_month=${thisMonth}&limit=1000`;
    }

    httpProvider.get(url, { headers: { 'Authorization': token } })
      .then(response => {
        const tasks = response.data.rows || [];
        setPageItems(tasks);
        setOriginalItems(tasks); // Store the original items
        setLoading(false);
      })
      .catch(error => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  }, [tabContextValue, token, currentDate]);

  const handleChangeTabContent = useCallback((event, newValue) => {
    setTabContextValue(newValue);
  }, []);

  const handleDeleteTask = useCallback((item) => {
    if (!window.confirm(`Do you want to remove task?`)) return;

    setLoading(true);
    httpProvider.delete(`/api/tasks/${item.id}`, { headers: { 'Authorization': token } })
      .then(() => {
        setPageItems(prevItems => prevItems.filter(dataItem => dataItem.id !== item.id));
        setOriginalItems(prevItems => prevItems.filter(dataItem => dataItem.id !== item.id));
        setLoading(false);
        setAlertInfo({ message: 'Task deleted', severity: 'success' });
      })
      .catch(error => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  }, [token]);

  const handleTaskTagClick = useCallback((tag) => {
    const filteredItems = originalItems.filter(item => item.tags.split(',').includes(tag));
    setPageItems(filteredItems);
    setIsTagSelected(true);
    setSelectedTag(tag);
  }, [originalItems]);

  const handleClearTagSelection = useCallback(() => {
    setPageItems(originalItems);
    setIsTagSelected(false);
    setSelectedTag('');
  }, [originalItems]);

  const handleUpdateTask = useCallback((row) => {
    setLoading(true);
    httpProvider.put(`/api/tasks/${row.id}`, row, { headers: { 'Authorization': token } })
      .then(() => {
        setPageItems(prevItems => prevItems.map(dataItem => dataItem.id === row.id ? { ...dataItem, ...row } : dataItem));
        setOriginalItems(prevItems => prevItems.map(dataItem => dataItem.id === row.id ? { ...dataItem, ...row } : dataItem));
        setLoading(false);
        setAlertInfo({ message: 'Task updated', severity: 'success' });
      })
      .catch(error => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  }, [token]);

  const handlePostponeTask = useCallback((row) => {
    row.due_date = dayjs(row.due_date).add(90, 'days').toISOString();
    handleUpdateTask(row);
  }, [handleUpdateTask]);

  const handleAddTask = useCallback((row) => {
    setLoading(true);
    httpProvider.post(`/api/tasks/add`, row, { headers: { 'Authorization': token } })
      .then(response => {
        setPageItems(prevItems => [response.data, ...prevItems]);
        setOriginalItems(prevItems => [response.data, ...prevItems]);
        setLoading(false);
        setAlertInfo({ message: 'Task added', severity: 'success' });
      })
      .catch(error => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  }, [token]);

  const handlePreviousDate = () => {
    setCurrentDate(prevDate => dayjs(prevDate).subtract(1, 'day').format('YYYY-MM-DD'));
  };

  const handleNextDate = () => {
    setCurrentDate(prevDate => dayjs(prevDate).add(1, 'day').format('YYYY-MM-DD'));
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <Grid container direction="row" columnSpacing={0} justifyContent="flex-start" sx={{ margin: 1 }}>
      <Breadcrumb breadcrumbs={breadcrumbs} />

      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid item xs={12} md={4}>
        <TaskForm onTaskAdd={handleAddTask} token={token} />
      </Grid>

      <Grid item md={4} >
        {loading ? (
          <Grid container justifyContent="center">
            <ThreeDotsLoader />
          </Grid>
        ) : (
          <TabContext value={tabContextValue}>
            <Grid container>
              <Grid item>
                <TabList onChange={handleChangeTabContent}>
                  {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabList>
              </Grid>
              <Grid item>
                {tabs.map(tab => (
                  <TabPanel key={tab.value} value={tab.value}>
                    <Grid container item xs={12}>
                      <Grid item>
                        {tab.value === '3' && (
                          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <NavigateBeforeIcon onClick={handlePreviousDate} color="primary">
                              Previous
                            </NavigateBeforeIcon>
                            <span>{currentDate}</span>
                            <NavigateNextIcon onClick={handleNextDate} color="primary">
                              Next
                            </NavigateNextIcon>
                          </Box>
                        )}
                      </Grid>
                      <Grid item>
                        <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                          {pageItems.length > 0 ? (
                            <>
                              <TagsComponent
                                tags={pageItems.map(item => item.tags.split(',')).flat()}
                                handleTagClick={handleTaskTagClick}
                              />
                              {isTagSelected && (
                                <IconButton onClick={handleClearTagSelection} color="primary" sx={{ ml: 1, mt: -2.5 }}>
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ paddingTop: 10 }}
                            >
                              <SpeakerNotesOffIcon titleAccess="No Tasks" sx={{ marginRight: 1 }} />
                              <Typography variant="body1">No results</Typography>
                            </Grid>
                          )}
                        </Box>
                      </Grid>

                      <Grid item>
                        {pageItems.length > 0 && (
                          <TasksTable
                            tasks={pageItems}
                            onDelete={handleDeleteTask}
                            onUpdate={handleUpdateTask}
                            onTagClick={handleTaskTagClick}
                            onPostpone={handlePostponeTask}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </TabContext>
        )}
      </Grid>
    </Grid >
  );
};

export default Tasks;
