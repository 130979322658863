import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import Grid from "@mui/material/Grid";
import httpProvider from "../provider/HttpProvider";
import BookmarksList from "../components/BookmarksList";
import Breadcrumb from "../components/Breadcrumb";
import CustomAlert from "../components/CustomAlert";
import SearchBookmarksForm from "../components/forms/SearchBookmarksForm";
import TablePagination from "../components/TablePagination";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import AddBookmarkForm from "../components/forms/AddBookmarkForm";
import PocketBookmarks from "../components/PocketBookmarks";
import Stats from "../components/Stats";

const Bookmarks = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const token = getToken();

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageItems, setPageItems] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ message: "", severity: "info" });
  const [pagination, setPagination] = useState({ count: 0, rowsPerPage, page: currentPage });

  const fetchBookmarks = () => {
    setLoading(true);
    const url = searchTerm
      ? `/api/bookmarks/search?limit=${rowsPerPage}&page=${currentPage}&term=${searchTerm}&sort=id&sortDesc=true`
      : `/api/bookmarks/?limit=${rowsPerPage}&page=${currentPage}&sort=id&sortDesc=true`;

    httpProvider
      .get(url, { headers: { Authorization: token } })
      .then(({ data }) => {
        setPageItems([{ section: "Bookmarks", items: data.rows }]);
        setPagination({ count: data.total_rows, rowsPerPage: data.limit || 0, page: data.page || 0 });
      })
      .catch((error) => setAlertInfo({ message: error.message, severity: "warning" }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBookmarks();
  }, [currentPage, rowsPerPage, searchTerm]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }}>
      <Breadcrumb breadcrumbs={[{ label: "Home", href: "/" }, { label: "Bookmarks" }]} />
      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
      <Grid item xs={12}>
        <SearchBookmarksForm searchTerm={searchTerm} handleSubmit={(e) => { e.preventDefault(); setSearchTerm(new FormData(e.target).get("search")); }} />
        <Stats />
      </Grid>
      <Grid item xs={12}>
        {loading ? <ThreeDotsLoader /> : (
          pageItems.map((list, index) => (
            <div key={index}>
              <Grid item xs={12} sx={{ marginTop: 5, marginBottom: 5 }}>
                <AddBookmarkForm token={token} onAddSuccess={(message) => {
                  setAlertInfo({ message, severity: "success" });
                  fetchBookmarks();
                }} onAddError={(msg) => setAlertInfo({ message: msg, severity: "warning" })} />
              </Grid>
              <Grid sx={{ marginTop: 5, marginBottom: 5 }}>
                <PocketBookmarks onAddSuccess={fetchBookmarks} onAddError={(msg) => setAlertInfo({ message: msg, severity: "warning" })} />
              </Grid>
              <TablePagination pagination={pagination} onChangePage={setCurrentPage} onChangeRowsPerPage={setRowsPerPage} loading={loading} />
              <BookmarksList list={list} onDelete={fetchBookmarks} onChipClick={(term) => setSearchTerm(term)} />
              <TablePagination pagination={pagination} onChangePage={setCurrentPage} onChangeRowsPerPage={setRowsPerPage} loading={loading} />
            </div>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default Bookmarks;