import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import httpProvider from './HttpProvider';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(localStorage.getItem("token"));

  const setToken = (newToken) => {
    localStorage.setItem('token', newToken);
    setToken_(newToken);
  };

  const getToken = () => {
    return token;
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    setToken_(null); // Ensure token state is also updated
  };

  const isTokenExpired = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      ).join(''));

      const decodedToken = JSON.parse(jsonPayload);
      const currentDate = new Date();
      return decodedToken.exp * 1000 < currentDate.getTime();
    } catch (error) {
      console.error("Invalid token", error);
      return true;
    }
  };

  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      removeToken();
      return;
    }

    const requestInterceptor = httpProvider.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = httpProvider.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Only remove the token if the error is a 401 Unauthorized
          removeToken();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      httpProvider.interceptors.request.eject(requestInterceptor);
      httpProvider.interceptors.response.eject(responseInterceptor);
    };
  }, [token]);

  const contextValue = useMemo(() => ({
    token,
    setToken,
    getToken,
    removeToken,
  }), [token]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
