import React, { useEffect, useState } from 'react';
import {
    Box, Typography, CircularProgress, List, ListItem,
    ListItemText, Button, Alert, IconButton, Tooltip
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import httpProvider from '../provider/HttpProvider';

const FileList = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFiles = () => {
        setLoading(true);
        setError(null);

        httpProvider.get('/api/upload/files')
            .then((response) => {
                setFiles(response.files);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching files:', error);
                setError('Failed to fetch files.');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchFiles(); // Load files on mount
    }, []);

    const handleDownload = async (filename) => {
        try {
            const response = await httpProvider.get(`/api/upload/download/${filename}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error('Download error:', err);
            setError('Failed to download file.');
        }
    };

    return (
        <Box sx={{ mt: 3, p: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
            {/* Title with Refresh Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">Uploaded Files</Typography>
                <Tooltip title="Refresh File List">
                    <IconButton color="primary" onClick={fetchFiles}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            {!loading && !error && files.length === 0 && (
                <Typography>No files uploaded yet.</Typography>
            )}

            {!loading && !error && files.length > 0 && (
                <List>
                    {files.map((file, index) => (
                        <ListItem
                            key={index}
                            sx={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <Tooltip title={file}>
                                <ListItemText
                                    primary={file.length > 35 ? `${file.substring(0, 30)}...` : file}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '350px'
                                    }}
                                />
                            </Tooltip>
                            <IconButton color="primary" onClick={() => handleDownload(file)}>
                                <DownloadIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default FileList;
