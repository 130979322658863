import React, { useState, useEffect } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { keyframes } from '@emotion/react';
import { Typography, Grid, Box, Link } from "@mui/material";

import CustomAlert from '../components/CustomAlert';
import Video from '../components/Video';

const slideInFromBottomLeft = keyframes`
  0% { transform: translateX(-100%) translateY(100%); opacity: 0; }
  100% { transform: translateX(0) translateY(0); opacity: 1; }
`;

const slideInFromTopRight = keyframes`
  0% { transform: translateX(100%) translateY(-100%); opacity: 0; }
  100% { transform: translateX(0) translateY(0); opacity: 1; }
`;

const slideInFromTop = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const Home = () => {
  const [state, setState] = useState({
    alertInfo: { message: '', severity: 'info' },
    loading: true,
    visitorId: ''
  });

  useEffect(() => {
    const fpPromise = FingerprintJS.load();
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        setState(prevState => ({
          ...prevState,
          visitorId: result.visitorId,
          loading: false
        }));
      })
      .catch(error => {
        console.error('Error loading fingerprint:', error);
      });

    return () => {
    };
  }, []);

  return (
    <Grid container justifyContent="flex-start">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }}>
        <CustomAlert message={state.alertInfo.message} severity={state.alertInfo.severity} />
        <Video />

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            padding: '8px 16px',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            margin: '0 1em 0 0',
            animation: `${slideInFromTop} 0.5s ease-out`,
          }}
        >
          <Link
            href="/login"
            color="inherit"
            variant="body2"
            sx={{
              fontSize: '0.75rem',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            Login
          </Link>
        </Box>

        {!state.loading && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              margin: '0 0 0 1em',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              borderRadius: 1,
              padding: '8px 16px',
              animation: `${slideInFromBottomLeft} 0.5s ease-out`
            }}
          >
            <Typography variant="body1"><small>{state.visitorId}</small></Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Home;
